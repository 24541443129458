<template>
	<section class="container">
		<div>
			<h1>Ændr KYC</h1>
		</div>
		<div class="row mt-2 mb-2">
			<div class="col-12">
				<hr />
			</div>
		</div>
		<div class="row">
			<b-form @submit.prevent="onSubmit">
				<div class="col-lg-6 col-12 lb pb-1 mt-1">
					<div class="row">
						<div class="col-12">
							<label><b>Fornavn</b></label>
							<InputText v-model="kyc.firstName"> </InputText>
						</div>
						<div class="col-12">
							<label><b>Efternavn</b></label>
							<InputText v-model="kyc.lastName"> </InputText>
						</div>
						<div class="col-12">
							<label><b>CPR</b></label>
							<InputText v-model="kyc.cpr"> </InputText>
						</div>
						<div class="col-12">
							<label><b>Primære email</b></label>
							<InputText v-model="kyc.email"> </InputText>
						</div>

						<div class="col-12">
							<label><b>Sekundær email</b></label>
							<InputText v-model="kyc.secondaryEmail"> </InputText>
						</div>

						<div class="col-12">
							<b> Klienttype</b>
							<select v-model="kyc.clientType" class="form-control">
								<option value="private">Privat</option>
								<option value="company">Firma (Reel ejer)</option>
							</select>
						</div>

						<div class="col-12">
							<b> KYC formål</b>
							<select v-model="kyc.kycPurpose" class="form-control">
								<option value="pension">Pension</option>
								<option value="companyFunds">Selskabsmidler</option>
								<option value="freeAssets">Frie midler</option>
							</select>
						</div>

						<div class="col-12">
							<label><b>ID-validering med MitID?</b></label>
						</div>
						<div class="col-12">
							<InputCheck v-model="kyc.kycSigned" />
						</div>

						<div class="col-12">
							<b> Dato </b>
							<InputDate v-model="kyc.kycDate" :disabled="kyc.kyc == false" :required="kyc.kyc == true"> </InputDate>
						</div>
						<div class="col-12">
							<b> Evalueringsinterval</b>

							<select v-model="kyc.evaluationInterval" class="form-control" :disabled="kyc.kyc == false || disabled">
								<option value=""></option>
								<option value="Årlig">Årlig</option>
								<option value="Hvert 3 år">Hvert 3 år</option>
								<option value="Hvert 5 år">Hvert 5 år</option>
							</select>
						</div>
					</div>
				</div>

				<div class="row mb-5 mt-3">
					<div class="col-12">
						<BaseActionButton class="btn-add" v-bind:clicked="clicked" v-bind:disabled="clicked" type="submit" id="signupButton">
							{{ $t('global.create') }}
						</BaseActionButton>
					</div>
				</div>
			</b-form>
		</div>
	</section>
</template>

<script>
import InputText from '@/modules/global/atomComponents/InputText'
import InputCheck from '@/modules/global/atomComponents/InputCheck'
import InputDate from '@/modules/global/atomComponents/InputDate'

export default {
	name: 'CompanyKycEdit',
	components: {
		InputText,
		InputCheck,
		InputDate,
	},
	async mounted() {
		console.log('Called')
		this.$parent.$emit('updateLoader')
	},
	data() {
		return {
			clicked: false,
			disabled: false,
		}
	},
	methods: {
		async onSubmit() {
			this.clicked = true
			try {
				var result = await this.$store.dispatch('companyVuex/updateCompany', { data: this.company, id: this.companyId })
				this.toast('Success', 'En KYC nu oprettet', true)
				this.$router.push({
					name: 'CompanyKycList',
					params: { companyId: this.companyId },
				})
			} catch (err) {
				this.toast('Error', 'Noget gik galt' + err, false)
				this.clicked = false
			}
			this.clicked = false
		},
	},
	computed: {
		companyId() {
			return this.$route.params.companyId
		},
		company() {
			return this.$store.getters['companyVuex/company']
		},
		index() {
			return this.$route.params.index
		},
		kyc: {
			get() {
				return this.company.kycList[this.index]
			},
			set(value) {
				this.company.kycList[this.index] = value
			},
		},
	},
}
</script>

<style></style>
